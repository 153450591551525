var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"share"},[_c('Menu',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center"},[_vm._v("Share of Search"),_c('Tag',{staticClass:"mt-4 ml-8",attrs:{"label":_vm.target,"type":"green"}})],1)]},proxy:true}])}),(_vm.isMounted)?[_c('ContainerForData',{staticClass:"mt-24",attrs:{"width":"100%","titleVisible":false,"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"data",fn:function(){return [(_vm.resp.stores && _vm.resp.terms)?[_c('Table',_vm._b({scopedSlots:_vm._u([{key:"searchCaption",fn:function(){return [_c('div',{staticClass:"table-title"},[_vm._v("Search Terms")])]},proxy:true},{key:"storeCaption",fn:function(ref){
var title = ref.title;
var addon = ref.addon;
return [_c('div',{staticClass:"table-title text-left small"},[_vm._v(_vm._s(title))]),(addon)?_c('div',{staticClass:"table-percent",class:{'--green': parseInt(addon) >= 30, '--red': parseInt(addon) < 30}},[_vm._v(_vm._s(addon))]):_vm._e()]}},{key:"name",fn:function(ref){
var item = ref.item;
var index = ref.index;
var col = ref.col;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"table-name-item"},[_vm._v(_vm._s(item.query))]),_c('FlagVariant',{staticClass:"flag-icon ml-8"}),_c('div',{staticClass:"ml-auto mr-8"},[_vm._v(_vm._s(_vm.getAverage(item))+"%")])],1)]}},{key:"store",fn:function(ref){
var item = ref.item;
var index = ref.index;
var value = ref.value;
var col = ref.col;
return [_c('div',{staticClass:"color-container flex items-center justify-center flex-col",class:_vm.getColor(_vm.getStoreValue(item, col)),on:{"click":function($event){return _vm.getSearchId(item, col)}}},[(_vm.getStoreValue(item, col) !== null)?[_c('div',[_vm._v(_vm._s(_vm.getStoreValue(item, col))+"%")])]:[_c('div',[_vm._v("No data")])]],2)]}}],null,false,3068302482)},'Table',_vm.searchOptions,false))]:[_vm._v("Sorry, no data")]]},proxy:true},(_vm.hasPagination)?{key:"pagination",fn:function(){return [_c('div',{staticClass:"element-container__content flex items-center justify-center"},[_c('pagination',{attrs:{"total":_vm.resp.count,"current":_vm.current,"limit":_vm.limit},on:{"change-page":function($event){_vm.current = $event}}})],1)]},proxy:true}:null],null,true)}),_c('div',{ref:"table"},[(_vm.terms)?_c('ContainerForData',{staticClass:"mt-24",attrs:{"width":"100%","titleVisible":false,"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"data",fn:function(){return [(_vm.tableOptions.data.length)?[_c('Table',_vm._b({staticClass:"brands__table",scopedSlots:_vm._u([{key:"position",fn:function(ref){
var item = ref.item;
var i = ref.i;
var value = ref.value;
return [_c('div',{staticClass:"table-rank"},[_c('div',{staticClass:"position flex justify-center"},[_vm._v(_vm._s(value.position))]),(value.rank)?_c('div',{staticClass:"percent-rank flex justify-center"},[_vm._v(_vm._s(value.rank)+"%")]):_vm._e()])]}},{key:"image",fn:function(ref){
var item = ref.item;
var i = ref.i;
var value = ref.value;
return [_c('div',{staticClass:"table-image",style:({backgroundImage: ("url(" + value + ")")})})]}},{key:"product",fn:function(ref){
var item = ref.item;
var i = ref.i;
var value = ref.value;
return [(item.show_url)?_c('a',{attrs:{"href":item.sku_url,"target":"_blank"}},[_vm._v(_vm._s(value.name))]):_c('div',{staticClass:"name"},[_vm._v(_vm._s(value.name))]),_c('div',[_c('strong',[_vm._v("RPC")]),_c('span',{staticClass:"ml-8"},[_vm._v(_vm._s(value.rpc))])]),_c('div',[_c('strong',[_vm._v("UPC")]),_c('span',{staticClass:"ml-8"},[_vm._v(_vm._s(value.upc))])])]}},{key:"brand",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"circle"},[_vm._v(_vm._s(value))])]}},{key:"manufacturer",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(value))])]}}],null,false,3602797382)},'Table',_vm.tableOptions,false))]:[_c('pre',[_vm._v("Data not found")])]]},proxy:true}],null,false,4066642058)}):_vm._e()],1)]:_c('div',{staticClass:"empty-filter"},[_vm._v("Пожалуйста, выберите значения фильтра")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }